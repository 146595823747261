import React from 'react'
import { graphql } from 'gatsby'
import Content from '../components/Content'
import DarkPageTemplate from '../templates/dark-page'

class TOC extends React.Component {
  render() {
    const terms = this.props.data.markdownRemark

    return (
      <DarkPageTemplate copyrightYear={'2022'}>
        <Content theme={'night'} smallerHeaders={true}>
          <div dangerouslySetInnerHTML={{ __html: terms.html }} />
        </Content>
      </DarkPageTemplate>
    )
  }
}

export default TOC

export const pageQuery = graphql`
  query TOCPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/terms.md/" }) {
      html
    }
  }
`
